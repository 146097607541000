import { anyPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';

import { ResponseErrors } from '^/common/error-handling/types';
import { getResponseErrors } from '^/common/error-handling/utils';
import { login, LOGIN } from '^/app/unauthenticated/auth/actions';
import LoginForm from '^/app/unauthenticated/auth/components/login/form';
import AuthWrapper from '^/app/unauthenticated/auth/components/wrapper';
import { StoreState } from '^/store/types';

interface StateProps {
  isLoading?: boolean;
  errors?: ResponseErrors;
}

type Props = StateProps & ResolveThunks<{ onLoginFormSubmit: typeof login }>;

export const Login: React.FunctionComponent<Props> = props => (
  <AuthWrapper>
    <div className="text-align-center">
      <img src="/static/images/MFMM_Logo_Teal_Horizontal.png" width="250px" />
    </div>
    <LoginForm
      onSubmit={props.onLoginFormSubmit}
      isLoading={props.isLoading}
      errors={props.errors}
    />
  </AuthWrapper>
);

const mapStateToProps = ({ responses }: StoreState): StateProps => {
  return {
    isLoading: anyPending(responses, [LOGIN]),
    errors: getResponseErrors(responses, LOGIN),
  };
};

export default connect(mapStateToProps, { onLoginFormSubmit: login })(Login);
