import { Column, Row } from '@dabapps/roe';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { getErrorData, hasFailed, isPending } from '@dabapps/redux-requests';
import { submit } from 'redux-form';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ObservationData, ObservationType, Song } from './types';
import { getResponseData } from '^/common/utils';
import { StoreState } from '^/store/types';
import {
  getPlaylistSongs,
  GET_PLAYLIST_SONGS,
  CREATE_OBSERVATION,
  createObservation,
  updateObservation,
  UPDATE_OBSERVATION,
  getObservation,
  GET_OBSERVATION,
  nextPage,
  previousPage,
  getIsObservationLocked,
  GET_IS_OBSERVATION_LOCKED,
} from './actions';
import ObservationForm from './observation-form';
import Loading from '^/common/components/loading';
import GenericError from '^/common/components/generic-error';
import { openModal } from '^/common/modals/actions';
import EndSessionModal from './modals/end-session-modal';
import MusicPlayer from '^/common/components/music-player';
import { OBSERVATION_FORM_INITIAL_VALUES } from './const';
import ReadonlyObservation from './observation-form/readonly-view';

interface StateProps {
  songs: ReadonlyArray<Song>;
  songsLoading: boolean;
  songsLoadingFailed: boolean;
  createObservationLoading: boolean;
  createObservationFailed: boolean;
  createObservationErrors: Record<string, string> | null;
  updateObservationLoading: boolean;
  updateObservationFailed: boolean;
  updateObservationErrors: Record<string, string> | null;
  getObservationLoading: boolean;
  getObservationFailed: boolean;
  shouldSubmitForm: boolean;
  observationData: ObservationData | null;
  observationPage: number;
  isObservationLocked: boolean | null;
  isObservationLockedLoading: boolean;
}

interface OwnProps {
  sessionId: string;
  observationType: ObservationType;
}

type DispatchProps = ResolveThunks<{
  getPlaylistSongs: typeof getPlaylistSongs;
  createObservation: typeof createObservation;
  updateObservation: typeof updateObservation;
  getObservation: typeof getObservation;
  nextPage: typeof nextPage;
  previousPage: typeof previousPage;
  submit: typeof submit;
  openModal: typeof openModal;
  getIsObservationLocked: typeof getIsObservationLocked;
}>;

type Props = StateProps & DispatchProps & OwnProps;

export class Observation extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.getPlaylistSongs(this.props.sessionId).then(() => {
      if (this.props.songs.length > 0) {
        this.props.getObservation(
          this.props.sessionId,
          this.props.songs[this.props.observationPage].song_slug,
          this.props.observationType
        );
      }
    });
    this.props.getIsObservationLocked(this.props.sessionId);
  }

  private onSubmitForm = (formData: Partial<ObservationData>) => {
    const songSlug = this.props.songs[this.props.observationPage].song_slug;

    if (this.props.observationData) {
      this.props
        .updateObservation(
          this.props.sessionId,
          songSlug,
          this.props.observationType,
          formData
        )
        .then(this.proceedToNextPage)
        .catch(() => {
          toast.error('Something went wrong.');
        });
      return;
    }

    const finalData = {
      ...formData,
      observation_type: this.props.observationType,
      song_slug: songSlug,
    };
    this.props
      .createObservation(this.props.sessionId, finalData)
      .then(this.proceedToNextPage)
      .catch(() => {
        toast.error('Something went wrong.');
      });
    return;
  };

  private delayGetObservation = _.debounce((page: number) => {
    const nextPageExceedsMaximum = page === this.props.songs.length - 1;
    if (nextPageExceedsMaximum) {
      this.props.getObservation(
        this.props.sessionId,
        this.props.songs[page - 1].song_slug,
        this.props.observationType
      );
    } else {
      this.props.getObservation(
        this.props.sessionId,
        this.props.songs[page].song_slug,
        this.props.observationType
      );
    }
  }, 500);

  private proceedToNextPage = () => {
    this.delayGetObservation(this.props.observationPage + 1);
    this.props.nextPage();
  };

  private onClickNextPage = () => {
    this.proceedToNextPage();
  };

  private onClickPreviousPage = () => {
    this.delayGetObservation(this.props.observationPage - 1);
    this.props.previousPage();
  };

  private onClickSubmit = () => {
    this.props.submit('observation');
  };

  private onClickEndSession = () => {
    this.props.openModal(<EndSessionModal sessionId={this.props.sessionId} />);
  };

  private renderFormOrReadonlyView = () => {
    if (this.props.getObservationLoading) {
      return <Loading />;
    }

    if (this.props.isObservationLocked) {
      return (
        <ReadonlyObservation observationData={this.props.observationData} />
      );
    }

    return (
      <ObservationForm
        initialValues={
          this.props.observationData || OBSERVATION_FORM_INITIAL_VALUES
        }
        errors={this.props.createObservationErrors}
        onSubmit={this.onSubmitForm}
      />
    );
  };

  public render() {
    if (this.props.songsLoading || this.props.isObservationLockedLoading) {
      return <Loading />;
    }

    if (this.props.songsLoadingFailed || !this.props.songs) {
      return <GenericError />;
    }

    if (this.props.songs.length === 0) {
      return (
        <p>
          The initial playlist for this session has zero songs.{' '}
          <Link to={`/research/${this.props.sessionId}/`}>
            Return to session overview
          </Link>
          .
        </p>
      );
    }

    const currentSong = this.props.songs[this.props.observationPage];

    return (
      <Row>
        <Column md={6}>
          <h3>Log results</h3>
          {this.props.observationType === 'MASTER' && (
            <MusicPlayer playlist={this.props.songs} />
          )}
          <hr />
          <p>
            Note reaction to song {this.props.observationPage + 1} of{' '}
            {this.props.songs.length} - {currentSong.artist} -{' '}
            {currentSong.title}
          </p>
          <hr />
          {this.renderFormOrReadonlyView()}
          <Row className="margin-vertical-base">
            <Column md={6}>
              <button
                disabled={this.props.observationPage === 0}
                onClick={this.onClickPreviousPage}
              >
                Previous
              </button>
            </Column>
            <Column md={6}>
              <div className="text-align-right">
                {this.props.shouldSubmitForm &&
                !this.props.isObservationLocked ? (
                  <button
                    onClick={this.onClickSubmit}
                    type="submit"
                    className="primary"
                  >
                    Save & Next
                  </button>
                ) : (
                  <button
                    disabled={
                      this.props.observationPage + 1 === this.props.songs.length
                    }
                    onClick={this.onClickNextPage}
                    className="primary"
                  >
                    Next
                  </button>
                )}
              </div>
            </Column>
          </Row>
          {this.props.observationType === 'MASTER' &&
            !this.props.isObservationLocked && (
              <>
                <hr />
                <div className="margin-top-large text-align-center">
                  <button onClick={this.onClickEndSession} className="error">
                    End session for all
                  </button>
                </div>
              </>
            )}
        </Column>
      </Row>
    );
  }
}

function mapStateToProps(state: StoreState) {
  const observationData = getResponseData(state.responses, GET_OBSERVATION)
    ?.data;
  const getObservationLoading = isPending(state.responses, GET_OBSERVATION);
  return {
    songs: getResponseData(state.responses, GET_PLAYLIST_SONGS)?.data,
    songsLoading: isPending(state.responses, GET_PLAYLIST_SONGS),
    songsLoadingFailed: hasFailed(state.responses, GET_PLAYLIST_SONGS),
    createObservationLoading: isPending(state.responses, CREATE_OBSERVATION),
    createObservationFailed: hasFailed(state.responses, CREATE_OBSERVATION),
    createObservationErrors: getErrorData(state.responses, CREATE_OBSERVATION)
      ?.response?.data,
    updateObservationLoading: isPending(state.responses, UPDATE_OBSERVATION),
    updateObservationFailed: hasFailed(state.responses, UPDATE_OBSERVATION),
    updateObservationErrors: getErrorData(state.responses, UPDATE_OBSERVATION)
      ?.response?.data,
    getObservationLoading,
    getObservationFailed: hasFailed(state.responses, GET_OBSERVATION),
    observationData,
    shouldSubmitForm: !observationData || state.observationFormChanged,
    observationPage: state.observationPage,
    isObservationLocked: getResponseData(
      state.responses,
      GET_IS_OBSERVATION_LOCKED
    )?.data?.observation_locked,
    isObservationLockedLoading: isPending(
      state.responses,
      GET_IS_OBSERVATION_LOCKED
    ),
  };
}

export default connect(mapStateToProps, {
  getPlaylistSongs,
  updateObservation,
  createObservation,
  getObservation,
  nextPage,
  previousPage,
  submit,
  openModal,
  getIsObservationLocked,
})(Observation);
